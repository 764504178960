import "../main.css";

function TeamMember({image, name, title, experience, logos, imageStyle, linkedIn}) {
  return (
    <section className="flex-col center center-text">
      <a href={linkedIn} target="_blank" rel="noopener noreferrer">
        <div className="med-circle-cropper">
          <img src={image} className={imageStyle} alt="circle-image" />
        </div>
      </a>
      <h1 className="profile-name">{name}</h1>
      <h2>{title}</h2>
      <div className="flex-row">
        {logos.map(logo => {
          return (
            <img src={logo} className="profile-school-image" alt={logo} />
          )
        })}
      </div>
      <div>
        {experience.map(exp => <p>{exp}</p>)}
      </div>
      <a className="linked-in" href={linkedIn} target="_blank" rel="noopener noreferrer">Connect on LinkedIn</a>
    </section>
  );
}

export default TeamMember;
